import React, { useState, useEffect, useRef, useMemo } from "react";
import Draggable from "react-draggable";
import { FaRegSquare, FaTimes } from "react-icons/fa";

const AboutWindow = ({
  closeWindow,
  typeOfWindow,
  aboutData,
  windowTitle,
  handleFocusWindow,
  zIndex,
}) => {
  const [windowFullScreenMode, setWindowFullScreenMode] = useState(false);
  const headerRef = useRef(null);
  const [programWindowHeight, setProgramWindowHeight] = useState(0);
  const [isCooldown, setIsCooldown] = useState(false); // New state for cooldown

  const handleFullScreen = () => {
    if (isCooldown) return; // Prevent further clicks during cooldown

    setWindowFullScreenMode((prev) => !prev);
    setIsCooldown(true); // Set cooldown state

    // Clear cooldown after a short delay (e.g., 500 ms)
    setTimeout(() => {
      setIsCooldown(false);
    }, 500);
  };

  useEffect(() => {
    if (headerRef.current) {
      setProgramWindowHeight(headerRef.current.offsetHeight);
    }
  }, [windowFullScreenMode]);

  const windowStyles = useMemo(
    () => ({
      zIndex,
    }),
    [zIndex]
  );

  const contentWrapperStyles = useMemo(
    () => ({
      height: `calc(100% - ${programWindowHeight}px)`,
    }),
    [programWindowHeight]
  );

  return (
    <Draggable
      defaultClassName="programWindow aboutMeWindow"
      handle=".programWindowHeader"
      disabled={windowFullScreenMode}
      position={windowFullScreenMode ? { x: 0, y: 0 } : null}
    >
      <div
        className={
          windowFullScreenMode ? `fullScreen ${typeOfWindow}` : typeOfWindow
        }
        onClick={handleFocusWindow}
        style={windowStyles}
      >
        <div className="programWindowHeader" ref={headerRef}>
          <ul>
            <li
              className="close"
              onClick={() => closeWindow("about")}
              onTouchEnd={() => closeWindow("about")}
            >
              <FaTimes />
            </li>
            <li className="fullscreen" onClick={handleFullScreen}>
              <FaRegSquare />
            </li>
          </ul>
          <h2 className="title">{windowTitle}</h2>
        </div>
        <div className="the-content-wrapper" style={contentWrapperStyles}>
          <div
            className="the-content"
            dangerouslySetInnerHTML={{ __html: aboutData.aboutContent }}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default AboutWindow;
