import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { FaRegSquare, FaTimes } from "react-icons/fa";
const CvWindow = ({
  closeWindow,
  typeOfWindow,
  theContent,
  windowTitle,
  handleFocusWindow,
  zIndex,
}) => {
  // Get header height
  const [windowFullScreenMode, setWindowFullScreenMode] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false); // New state for cooldown
  const [cvWindowHeight, setCvWindowHeight] = useState(0);

  const handleFullScreen = () => {
    if (isCooldown) return; // Prevent further clicks during cooldown

    setWindowFullScreenMode((prev) => !prev);
    setIsCooldown(true); // Set cooldown state

    // Clear cooldown after a short delay (e.g., 500 ms)
    setTimeout(() => {
      setIsCooldown(false);
    }, 500);
  };
  useEffect(() => {
    setCvWindowHeight(document.querySelector(".cvWindowHeader").offsetHeight);
  }, [windowFullScreenMode]);
  return (
    <>
      <Draggable
        defaultClassName="cvWindow programWindow"
        handle=".cvWindowHeader"
        disabled={windowFullScreenMode}
        position={windowFullScreenMode ? { x: 0, y: 0 } : null}
      >
        <div
          className={
            windowFullScreenMode ? `fullScreen ${typeOfWindow}` : typeOfWindow
          }
          onClick={handleFocusWindow}
          style={{
            zIndex: zIndex,
          }}
        >
          <div className="cvWindowHeader programWindowHeader">
            <ul>
              <li
                className="close"
                onClick={() => closeWindow("myCv")}
                onTouchEnd={() => closeWindow("myCv")}
              >
                <FaTimes />
              </li>
              <li
                className="fullscreen"
                onClick={handleFullScreen}
                onTouchEnd={handleFullScreen}
              >
                <FaRegSquare />
              </li>
            </ul>
            <h2 className="title">{windowTitle}</h2>
          </div>
          <div
            className="the-content-wrapper"
            style={{
              height: `calc(100% - ${cvWindowHeight}px)`,
            }}
          >
            <iframe
              src={theContent.data}
              title="CV"
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default CvWindow;
