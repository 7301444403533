import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect/dist/core";
import logo from "../../src/assets/imgs/logo-white.png";
const Preloader = () => {
  const fullTimeAndDate = new Date().toLocaleString();
  const textPhrases = [
    "BIOS Starting...",
    " ",
    // ` HNBIOS (C) 1982 Motherboard, Inc.`,
    `BIOS VERSION: 1.0.0`,
    `BIOS TYPE: 286`,
    `BIOS Date: ${fullTimeAndDate}`,
    ` `,
    `Checking GPU...`,
    `GPU: PotatoGpuGTX3000`,
    "GPU Driver: OfcourseNotIDKHowYouGotToRunThisDevice v14.6",
    " ",
    `Checking CPU...`,
    `CPU: Pentinum 2.5`,
    " ",
    "Checking Display Devices...",
    'Display Device: YouTellMe 24" UltraSD 144hz',
    " ",
    `Press F11 to enter Full Screen Mode`,
    `Booting from Soft Disk...`,
    " ",
    `Done`,
    " ",
    `Starting hnOS 1996`,
  ];

  const [text, setText] = useState("");
  const [typeWriterFinished, setTypeWriterFinished] = useState(false);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const typewriter = new Typewriter(text, {
      loop: false,
      delay: 0.1,
      cursor: "",
    });

    for (let i = 0; i < textPhrases.length; i++) {
      if (textPhrases[i] === " ") {
        typewriter.typeString("<p><br /></p>").pauseFor(10).start();
      } else {
        typewriter
          .typeString("<p>" + textPhrases[i] + "</p>")
          .pauseFor(20)
          .callFunction(() => {
            if (i === textPhrases.length - 1) {
              setTimeout(() => {
                setTypeWriterFinished(true);
              }, 1000);
            }
          })
          .start();
      }
    }
  }, [text]);

  useEffect(() => {
    if (typeWriterFinished) {
      const interval = setInterval(() => {
        if (progress === 100) {
          setTimeout(() => {
            setFullyLoaded(true);
          }, 500);
          return;
        }
        setProgress((progress) => progress + 1);
      }, 20);
      return () => clearInterval(interval);
    }
  }, [typeWriterFinished, progress]);
  return (
    <>
      {!fullyLoaded && (
        <div
          id="preloader"
          className={
            (typeWriterFinished ? "done" : "not-done") +
            (fullyLoaded ? " fully-loaded" : "")
          }
        >
          <div className="text-message" ref={(el) => setText(el)} />
          {typeWriterFinished && (
            <div className="os-screen">
              <div className="wrapper">
                <div className="logo-wrapper">
                  <img src={logo} alt="HN System Starting" />
                </div>
                <div className="w-100">
                  <div
                    className="progress-bar"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Preloader;
