import React, { useState, useEffect } from "react";
import { useAllApiData } from "../Config";
import { handleStartMenuItemClick } from "../Config";
const StartMenu = ({ className }) => {
  const allApiData = useAllApiData();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const header = document.querySelector("#taskbar");
    setHeaderHeight(header.offsetHeight);
  }, []);

  //   On window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      const header = document.querySelector("#taskbar");
      setHeaderHeight(header.offsetHeight);
    });
  }, []);

  return (
    <>
      <div
        className={"start-menu-container" + (className ? " " + className : "")}
        style={{
          top: headerHeight,
        }}
      >
        {allApiData &&
          allApiData.header.data.menu_items.map((item, key) => {
            return (
              <h4
                key={key}
                data-call_for={item.call_for}
                className="menu-item"
                onClick={(e) => handleStartMenuItemClick(e, item.call_for)}
              >
                {item.title}
              </h4>
            );
          })}
      </div>
    </>
  );
};

export default StartMenu;
