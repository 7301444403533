import React, { useState } from "react";
import logoWhite from "../../src/assets/imgs/logo-white.png";
import logoDark from "../../src/assets/imgs/logo-dark.png";
import Clock from "./Clock";
import { apiURL, handleStartMenuItemClick } from "../Config";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import StartMenu from "./StartMenu";

import { useEffect } from "react";
const Taskbar = () => {
  const [hoverLogo, setHoverLogo] = useState(false);
  const [startMenu, setStartMenu] = useState(false);
  const [socialMedia, setSocialMedia] = useState({});
  const handleLogoHover = () => {
    setHoverLogo(!hoverLogo);
  };

  const handleStartMenu = () => {
    setStartMenu(!startMenu);
  };

  useEffect(() => {
    //social-media
    fetch(`${apiURL}/social-media`)
      .then((res) => res.json())
      .then((data) => {
        setSocialMedia(data);
      });
  }, []);

  return (
    <div id="taskbar">
      <div className="start-menu">
        <div
          className="start-menu-button"
          onMouseEnter={handleLogoHover}
          onMouseLeave={handleLogoHover}
          onClick={handleStartMenu}
        >
          <img src={hoverLogo ? logoWhite : logoDark} alt="Start Logo" />
        </div>
        <StartMenu className={startMenu ? "active" : ""} />
      </div>
      <div className="options">
        {/* <ul>
          <li>##</li>
          <li>###</li>
        </ul> */}
      </div>
      <div className="toolbar d-flex align-content-center flex-wrap justify-content-center align-items-center">
        <ul className="social-media d-flex align-content-center flex-wrap justify-content-center align-items-center">
          {socialMedia.length > 0 &&
            socialMedia.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    title={item.name}
                  >
                    {item.name === "github" && <FaGithub />}
                    {item.name === "linkedin" && <FaLinkedin />}
                    {item.name === "email" && <FaEnvelope />}
                  </a>
                </li>
              );
            })}
        </ul>

        <p>
          <Clock />
        </p>
      </div>
    </div>
  );
};

export default Taskbar;
