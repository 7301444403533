import { useEffect } from "react";

export const usePreloadImages = (images) => {
    useEffect(() => {
        images.forEach((url) => {
            const img = new Image();
            img.src = url;
        });
    }, [images]);
};
