import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { FaTimes } from "react-icons/fa";
import { BiLoader } from "react-icons/bi";
import { apiURL } from "../Config";

const ContactForm = ({
  closeWindow,
  typeOfWindow,
  theContent,
  windowTitle,
  handleFocusWindow,
  zIndex,
}) => {
  const [windowFullScreenMode, setWindowFullScreenMode] = useState(false);
  const headerRef = useRef(null);
  const [cvWindowHeight, setCvWindowHeight] = useState(0);
  const [formStatus, setFormStatus] = useState({
    status: "idle",
    message: "",
  });

  useEffect(() => {
    if (headerRef.current) {
      setCvWindowHeight(headerRef.current.offsetHeight);
    }
  }, [windowFullScreenMode]);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setFormStatus({ status: "loading", message: "" });

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch(
        `${apiURL}/contact-form?formId=${theContent[0].id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (result.status === "success") {
        e.target.reset();
        setFormStatus({ status: "success", message: result.message });
      } else if (result.status === "error") {
        setFormStatus({ status: "error", message: result.message });
        document.querySelectorAll(".form-field.error").forEach((field) => {
          field.classList.remove("error");
        });
        result.failedFields.forEach((field) => {
          document
            .querySelector(`.contactFormWindow form [name=${field}]`)
            .classList.add("error");
        });
      }
    } catch (error) {
      console.error(error);
      setFormStatus({
        status: "error",
        message: "An error occurred. Please try again later.",
      });
    }
  };

  return (
    <Draggable
      defaultClassName="contactFormWindow programWindow"
      handle=".contactFormWindowHeader"
      disabled={windowFullScreenMode}
      position={windowFullScreenMode ? { x: 0, y: 0 } : null}
    >
      <div
        className={
          windowFullScreenMode ? `fullScreen ${typeOfWindow}` : typeOfWindow
        }
        onClick={handleFocusWindow}
        style={{ zIndex }}
      >
        <div
          className="contactFormWindowHeader programWindowHeader"
          ref={headerRef}
        >
          <ul>
            <li
              className="close"
              onClick={() => closeWindow("contact")}
              onTouchEnd={() => closeWindow("contact")}
            >
              <FaTimes />
            </li>
          </ul>
          <h2 className="title">{windowTitle}</h2>
        </div>
        <div
          className="the-content-wrapper"
          style={{
            height: `calc(100% - ${cvWindowHeight}px)`,
          }}
        >
          <form
            name="contact"
            method="POST"
            onSubmit={handleSubmitForm}
            className={formStatus.status}
          >
            {theContent[0].form_fields.map((field, key) => (
              <div className="form-field" key={key}>
                <label htmlFor={field.name}>
                  {field.name
                    .replace(/[-_]/g, " ")
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                </label>
                {field.basetype === "textarea" ? (
                  <textarea
                    name={field.name}
                    id={field.name}
                    cols="30"
                    rows="10"
                  ></textarea>
                ) : (
                  <input
                    type={field.basetype}
                    name={field.name}
                    id={field.name}
                    placeholder={field.placeholder}
                  />
                )}
              </div>
            ))}
          </form>
          {formStatus.status === "success" && (
            <div className="form-status-msg form-success">
              <p className="msg">{formStatus.message}</p>
            </div>
          )}
          {formStatus.status === "error" && (
            <div className="form-status-msg form-error">
              <p className="msg">{formStatus.message}</p>
            </div>
          )}
          {formStatus.status === "loading" && (
            <div className="form-status-msg form-loading">
              <BiLoader />
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default ContactForm;
