import React, { useState, useEffect } from "react";

import Draggable from "react-draggable";
import { FaRegSquare, FaTimes } from "react-icons/fa";

const ExperienceWindow = ({
  closeWindow,
  typeOfWindow,
  theContent,
  windowTitle,
  handleFocusWindow,
  zIndex,
}) => {
  const [windowFullScreenMode, setWindowFullScreenMode] = useState(false);
  const [programWindowHeight, setProgramWindowHeight] = useState(0);
  const [isCooldown, setIsCooldown] = useState(false); // New state for cooldown

  const handleFullScreen = () => {
    if (isCooldown) return; // Prevent further clicks during cooldown

    setWindowFullScreenMode((prev) => !prev);
    setIsCooldown(true); // Set cooldown state

    // Clear cooldown after a short delay (e.g., 500 ms)
    setTimeout(() => {
      setIsCooldown(false);
    }, 500);
  };

  useEffect(() => {
    setProgramWindowHeight(
      document.querySelector(".programWindowHeader").offsetHeight
    );
  }, [windowFullScreenMode]);
  return (
    <Draggable
      defaultClassName="programWindow"
      handle=".programWindowHeader"
      disabled={windowFullScreenMode}
      position={windowFullScreenMode ? { x: 0, y: 0 } : null}
      //mobile support
      onTouchStart={() => handleFocusWindow()}
    >
      <div
        className={
          windowFullScreenMode ? `fullScreen ${typeOfWindow}` : typeOfWindow
        }
        onClick={handleFocusWindow}
        style={{ zIndex: zIndex }}
      >
        <div className="programWindowHeader">
          <ul>
            <li
              className="close"
              onClick={() => closeWindow("experience")}
              onTouchEnd={() => closeWindow("experience")}
            >
              <FaTimes />
            </li>
            <li
              className="fullscreen"
              onClick={handleFullScreen}
              onTouchEnd={handleFullScreen}
            >
              <FaRegSquare />
            </li>
          </ul>
          <h2 className="title">Experience</h2>
        </div>
        <div
          className="the-content-wrapper"
          style={{
            height: `calc(100% - ${programWindowHeight}px)`,
          }}
        >
          <div className="experience-wrapper">
            <div className="tr">
              <div className="th">Logo</div>
              <div className="th">Name</div>
              <div className="th">Position</div>
              <div className="th">Time</div>
            </div>
            {theContent &&
              theContent.map((experience, key) => (
                <div className="tr" key={key}>
                  <div className="td">
                    <div className="img-wrapper">
                      <img
                        src={experience.experienceImg}
                        alt={experience.experienceTitle}
                      />
                    </div>
                  </div>
                  <div className="td">{experience.experienceTitle}</div>
                  <div className="td">{experience.experienceContent}</div>
                  <div className="td">{experience.experienceDate}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default ExperienceWindow;
