import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { FaRegSquare, FaTimes } from "react-icons/fa";
import SingleProjectWindow from "./SingleProjectWindow";
import { apiURL } from "../Config";
import { BiLoader } from "react-icons/bi";

const ProjectsWindow = ({
  closeWindow,
  typeOfWindow,
  theContent,
  windowTitle,
  handleFocusWindow,
  zIndex,
}) => {
  const [windowFullScreenMode, setWindowFullScreenMode] = useState(false);
  const [programWindowHeight, setProgramWindowHeight] = useState(0);
  const [singleProjectData, setSingleProjectData] = useState({
    data: "",
    loaded: false,
  });
  const [isCooldown, setIsCooldown] = useState(false);
  const [preloadedImages, setPreloadedImages] = useState(false);

  const handleFullScreen = () => {
    if (isCooldown) return;
    setWindowFullScreenMode((prev) => !prev);
    setIsCooldown(true);
    setTimeout(() => {
      setIsCooldown(false);
    }, 500);
  };

  const preloadImages = () => {
    const promises = theContent.map((project) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = project.image;
        img.onload = resolve;
        img.onerror = resolve;
      });
    });
    Promise.all(promises).then(() => setPreloadedImages(true));
  };

  useEffect(() => {
    preloadImages();
  }, [theContent]);

  const handleProjectClick = (projectId) => {
    document.querySelector("#desktopScreen").classList.add("busyCursor");
    const controller = new AbortController();
    const { signal } = controller;
    const projects = document.querySelectorAll(".single-project");
    projects.forEach((project) => project.classList.remove("loading"));
    document.querySelector(`#project-${projectId}`).classList.add("loading");

    fetch(`${apiURL}/projects/${projectId}`, { signal })
      .then((res) => res.json())
      .then((data) => {
        setSingleProjectData({
          data: data,
          loaded: true,
        });
      })
      .finally(() => {
        document.querySelector("#desktopScreen").classList.remove("busyCursor");
        projects.forEach((project) => project.classList.remove("loading"));
      });

    return () => controller.abort();
  };

  const closeProject = () => {
    setSingleProjectData({
      data: "",
      loaded: false,
    });
  };

  useEffect(() => {
    setProgramWindowHeight(
      document.querySelector(".programWindowHeader").offsetHeight
    );
  }, [windowFullScreenMode]);

  return (
    <Draggable
      defaultClassName="programWindow"
      handle=".programWindowHeader"
      disabled={windowFullScreenMode}
      position={windowFullScreenMode ? { x: 0, y: 0 } : null}
    >
      <div
        className={
          windowFullScreenMode ? `fullScreen ${typeOfWindow}` : typeOfWindow
        }
        onClick={handleFocusWindow}
        style={{ zIndex: zIndex }}
      >
        <div className="programWindowHeader">
          <ul>
            <li className="close" onClick={() => closeWindow("projects")}>
              <FaTimes />
            </li>
            <li className="fullscreen" onClick={handleFullScreen}>
              <FaRegSquare />
            </li>
          </ul>
          <h2 className="title">Projects</h2>
        </div>
        <div
          className="the-content-wrapper"
          style={{
            height: `calc(100% - ${programWindowHeight}px)`,
          }}
        >
          <div className="projects-gallery-wrapper">
            <div className="projects-navigation">
              {preloadedImages &&
                theContent.map((project) => (
                  <div
                    className="single-project"
                    id={`project-${project.id}`}
                    key={project.id}
                    onClick={() => handleProjectClick(project.id)}
                  >
                    <div className="img-wrapper">
                      <img src={project.image} alt={project.title} />
                    </div>
                    <div className="content-wrapper">
                      <h2>{project.title}</h2>
                    </div>
                  </div>
                ))}
              {!preloadedImages && (
                <div className="loading-images">
                  <BiLoader />
                </div>
              )}
            </div>
          </div>
        </div>
        <SingleProjectWindow
          isItActive={singleProjectData.loaded}
          programWindowHeight={programWindowHeight}
          singleProjectData={singleProjectData.data}
          closeProject={closeProject}
        />
      </div>
    </Draggable>
  );
};

export default ProjectsWindow;
