import React, { useState } from "react";
import DesktopBackground from "./DesktopBackground";
import DesktopScreen from "./DesktopScreen";
import Taskbar from "./Taskbar";

const Desktop = () => {
  return (
    <div id="desktop-wrapper">
      <DesktopScreen />
      <Taskbar />
      <DesktopBackground />
    </div>
  );
};

export default Desktop;
