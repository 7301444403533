import './assets/style/index.scss';
import Desktop from './components/Desktop';
import Preloader from './components/Preloader';
import { Helmet } from 'react-helmet';
import { useAllApiData } from "./Config";
import favIcon from '../src/assets/imgs/favicon.png';
export const apiURL = process.env.REACT_APP_API_BASE;

function App() {
  const allApiData = useAllApiData();
  return (
    <>
      <Helmet>
        <link rel="icon" href={allApiData?.seo?.data[0].favicon ? allApiData?.seo?.data[0].favicon : favIcon} />
        <title>{
          allApiData?.seo?.data[0].title ? allApiData?.seo?.data[0].title : 'Hnikoloski - WebPortfolio'
        }</title>
        <meta name="description" content={
          allApiData?.seo?.data[0].description ? allApiData?.seo?.data[0].description : 'I am a WordPress Developer passionate about UI and aesthetics I strive for clean, maintainable, and reusable code.'
        } />
        <meta name="keywords" content={
          allApiData?.seo?.data[0].keywords ? allApiData?.seo?.data[0].keywords : 'hnikoloski, hristijan, nikoloski, wordpress, wordpress developer, full stack'
        } />


      </Helmet>
      <Preloader />
      <Desktop />
    </>
  );
}

export default App;
