import React, { useEffect, useState } from "react";
import DesktopIcon from "./DesktopIcon";
import { apiURL, useAllApiData, handleStartMenuItemClick } from "../Config";
import AboutWindow from "./AboutWindow";
import ProjectsWindow from "./ProjectsWindow";
import CvWindow from "./CvWindow";
import ContactForm from "./ContactForm";
import ExperienceWindow from "./ExperienceWindow";
import { usePreloadImages } from "../hooks/usePreloadImages";
const DesktopScreen = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [windowIcons, setWindowIcons] = useState({});
  const [windowZindex, setWindowZindex] = useState(0);
  const [aboutData, setAboutData] = useState({
    data: "",
    loaded: false,
  });
  const [projectsData, setProjectsData] = useState({
    data: "",
    loaded: false,
  });
  const [pdfData, setPdfData] = useState({
    data: "",
    loaded: false,
  });
  const [contactForm, setContactForm] = useState({
    data: "",
    loaded: false,
  });
  const [experienceData, setExperienceData] = useState({
    data: "",
    loaded: false,
  });

  const allApiData = useAllApiData();
  // Preload images
  // Initialize projectImages as an empty array if data is not yet available
  const projectImages =
    allApiData?.projects?.data?.projects?.map((project) => project.image) || [];

  // Always call the hook, even if `projectImages` is empty
  usePreloadImages(projectImages);

  useEffect(() => {
    const header = document.querySelector("#taskbar");
    setHeaderHeight(header.offsetHeight);
  }, []);
  //   On window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      const header = document.querySelector("#taskbar");
      setHeaderHeight(header.offsetHeight);
    });
  }, []);

  const handleClickOutside = (e) => {
    if (e.target.closest(".desktop-icon") === null) {
      document.querySelectorAll(".desktop-icon").forEach((el) => {
        el.classList.remove("singleClick");
      });
    }
  };

  const handleCloseWindow = (windowToClose) => {
    if (windowToClose === "about") {
      setAboutData({
        data: "",
        loaded: false,
        zIndex: 0,
      });
    } else if (windowToClose === "projects") {
      setProjectsData({
        data: "",
        loaded: false,
        zIndex: 0,
      });
    } else if (windowToClose === "myCv") {
      setPdfData({
        data: "",
        loaded: false,
        zIndex: 0,
      });
    } else if (windowToClose === "contact") {
      setContactForm({
        data: "",
        loaded: false,
        zIndex: 0,
      });
    } else if (windowToClose === "experience") {
      setExperienceData({
        data: "",
        loaded: false,
        zIndex: 0,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Get data from /header
    fetch(`${apiURL}/header`)
      .then((res) => res.json())
      .then((data) => {
        setWindowIcons(data.menu_items);
      });
  }, []);

  const handleFocusWindow = (e) => {
    const clickedWindow = e.target.closest(".programWindow");
    if (document.querySelectorAll(".programWindow").length === 1) {
      // console.log("This is the only window");
      return;
    }
    const highestZIndex = Math.max(
      ...Array.from(document.querySelectorAll(".programWindow")).map((el) =>
        Number(el.style.zIndex)
      )
    );
    if (clickedWindow.style.zIndex === highestZIndex.toString()) {
      // console.log("This window is already focused");
      return;
    } else {
      clickedWindow.style.zIndex = highestZIndex + 1;
      // Reduce the z-index of all other windows
    }

    // If the clicked window is not the highest z-index, set it to the highest z-index
  };

  const handleDoubleClick = (e) => {
    // Fetch data from API
    var callFor = e.target.closest(".desktop-icon").dataset.call_for;
    var totalWindows = document.querySelectorAll(".programWindow").length;
    if (callFor === "about") {
      setAboutData({
        data: allApiData.about.data,
        loaded: true,
        zIndex: totalWindows + 1,
      });
    }
    if (callFor === "projects") {
      setProjectsData({
        data: allApiData.projects.data.projects,
        loaded: true,
        zIndex: totalWindows + 1,
      });
    }
    if (callFor === "myCv") {
      function getPdfLink() {
        let resultsArray = [];
        allApiData.header.data.menu_items.forEach((item) => {
          resultsArray.push(item);
        });

        let pdfLink = resultsArray.filter((item) => item.call_for === "myCv");
        return pdfLink[0].url;
      }
      setPdfData({
        data: getPdfLink(),
        loaded: true,
        zIndex: totalWindows + 1,
      });
    }
    if (callFor === "contact-form") {
      setContactForm({
        data: allApiData.contact_form,
        loaded: true,
        zIndex: totalWindows + 1,
      });
    }
    if (callFor === "experience") {
      setExperienceData({
        data: allApiData.experience.data,
        loaded: true,
        zIndex: totalWindows + 1,
      });
    }
  };
  const handleSingleClick = (e) => {
    document.querySelectorAll(".desktop-icon").forEach((el) => {
      el.classList.remove("singleClick");
    });
  };
  useEffect(() => {
    // Set the z-index of all windows
    const allWindows = document.querySelectorAll(".programWindow");
    allWindows.forEach((el) => {
      el.style.zIndex = windowZindex;
    });
  }, [windowZindex]);

  useEffect(() => {}, []);

  return (
    <div
      id="desktopScreen"
      style={{
        height: `calc(100vh - ${headerHeight}px)`,
      }}
    >
      <div className="desktop-icons-wrapper">
        {windowIcons.length > 0 &&
          windowIcons.map((item) => {
            return (
              <DesktopIcon
                key={item.id}
                handleDoubleClick={handleDoubleClick}
                theTitle={item.title}
                typeOfIcon={item.type_of_icon}
                callFor={item.call_for}
                handleSingleClick={handleSingleClick}
              />
            );
          })}
      </div>
      {aboutData.loaded && (
        <AboutWindow
          closeWindow={handleCloseWindow}
          handleFocusWindow={handleFocusWindow}
          aboutData={aboutData.data}
          windowTitle="About"
          zIndex={aboutData.zIndex}
        />
      )}
      {projectsData.loaded && (
        <ProjectsWindow
          closeWindow={handleCloseWindow}
          handleFocusWindow={handleFocusWindow}
          windowTitle="Projects"
          typeOfWindow="projects"
          theContent={projectsData.data}
          zIndex={projectsData.zIndex}
        />
      )}
      {pdfData.loaded && (
        <CvWindow
          closeWindow={handleCloseWindow}
          handleFocusWindow={handleFocusWindow}
          windowTitle="CV.pdf"
          typeOfWindow="pdf"
          theContent={pdfData}
          zIndex={pdfData.zIndex}
        />
      )}
      {contactForm.loaded && (
        <ContactForm
          closeWindow={handleCloseWindow}
          handleFocusWindow={handleFocusWindow}
          windowTitle="Contact"
          typeOfWindow="contact-form"
          theContent={contactForm.data}
          zIndex={contactForm.zIndex}
        />
      )}
      {experienceData.loaded && (
        <ExperienceWindow
          closeWindow={handleCloseWindow}
          handleFocusWindow={handleFocusWindow}
          windowTitle="Experience"
          typeOfWindow="experience"
          theContent={experienceData.data}
          zIndex={experienceData}
        />
      )}
    </div>
  );
};

export default DesktopScreen;
