import { useState, useEffect } from "react";
export const apiURL = process.env.REACT_APP_API_BASE;

export function useAllApiData() {
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    fetch(`${apiURL}/all-data`)
      .then((res) => res.json())
      .then((data) => {
        setApiData(data);
      });
  }, []);

  return apiData;
}

export function handleStartMenuItemClick(e, callFor) {
  //  Get .desktop-icon and simulate a double click
  const desktopIcon = document.querySelector(
    `.desktop-icon[data-call_for="${callFor}"]`
  );
  const startIcon = document.querySelector(".start-menu-button");

  desktopIcon.dispatchEvent(new MouseEvent("dblclick", { bubbles: true }));

  startIcon.dispatchEvent(new MouseEvent("click", { bubbles: true }));

  return false;
}
