import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { FaEnvelope } from "react-icons/fa";
import { ImTerminal } from "react-icons/im";
import { IoDocumentTextSharp } from "react-icons/io5";
import { VscFilePdf } from "react-icons/vsc";

const DesktopIcon = ({
  handleDoubleClick,
  theTitle,
  typeOfIcon,
  callFor,
  handleSingleClick,
}) => {
  const handleClickOutside = (e) => {
    if (e.target.closest(".desktop-icon") === null) {
      document.querySelectorAll(".desktop-icon").forEach((el) => {
        el.classList.remove("singleClick");
      });
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [iconProps, setIconProps] = useState({
    icon: <IoDocumentTextSharp />,
    title: "About",
  });
  useEffect(() => {
    if (typeOfIcon === "notepad") {
      setIconProps({
        icon: <IoDocumentTextSharp />,
        title: theTitle,
      });
    } else if (typeOfIcon === "email" || typeOfIcon === "envelope") {
      setIconProps({
        icon: <FaEnvelope />,
        title: theTitle,
      });
    } else if (typeOfIcon === "exe" || typeOfIcon === "projects") {
      setIconProps({
        icon: <ImTerminal />,
        title: theTitle,
      });
    } else if (typeOfIcon === "pdf") {
      setIconProps({
        icon: <VscFilePdf />,
        title: theTitle,
      });
    }
  }, [typeOfIcon, theTitle]);

  return (
    <Draggable defaultClassName="desktop-icon">
      <div
        onClick={handleSingleClick}
        onDoubleClick={handleDoubleClick}
        data-item_type={typeOfIcon}
        data-call_for={callFor}
        onTouchStart={handleDoubleClick}
      >
        <div className="icon-wrapper">{iconProps.icon}</div>
        <p>{iconProps.title}</p>
      </div>
    </Draggable>
  );
};

export default DesktopIcon;
